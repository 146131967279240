body, * {
  margin: 0;
  font-family:'Open Sans',-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
    font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.67;
  letter-spacing: 0.18px;
  color: #242f33;
}

:root {
  --black: #242f33;
  --grey100: #62757a;
  --grey90: #9da2a3;
  --grey80: #d3d3db;
  --grey60: #ebebf0;
  --red: #e60c0c;
  --light-red: #d62b2b;
  --very-light-red: #debdbd;
  --green: #05b74a;
  --light-green: #a0dbb7;
  --blue: #2077d5;
  --light-blue: #a2ccfa;
  --very-light-blue: #d9eafc;
  --green: #006e90;
  --light-green: #bdd6de;
  --orange: #e66c0c;
  --light-orange: #ded0bd;
  --ice-blue: #e5f0f3;
  --white: #ffffff;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  text-decoration: none;
}

hr{
  border-style: none;
	margin: 0;
	border-top: solid 1px #ebebf0;
}