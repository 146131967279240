.container {
	display: flex;
	justify-content: space-between;
}

.img {
	width: 100px;
	height: 75px;
	margin-left: 50px;
}

.data {
	text-align: left;
}