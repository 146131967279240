.header {
	display: flex;
	justify-content: space-between;
	height: 56px;
	background-color: #ffffff;
	border-bottom: solid 1px #d3d3db;
	padding: 8px 16px;
}

.exit {
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	transition: transform 0.3s ease-in-out;
}

.exit:hover {
	transform: rotate(90deg);
}

.overlay {
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	transition: transform 0.3s ease-in-out;
	transform: translateY(100vh);
	z-index: 20;
	background-color: rgba(255, 255, 255, 0.75);
	display: flex;
	justify-content: center;
	align-items: center;
}

.container {
	width: 100%;
	min-height: 100vh;
}

.show {
	transform: translateY(0);
}

.content {
	height: calc(100vh - 56px);
	background-color: #fbfbfb;
	display: flex;
	flex-direction: column;
}

.content > div:first-child {
	flex-grow: 1;
}

.title {
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 14px;
	font-weight: 600;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.71;
	letter-spacing: normal;
	color: #242f33;
}

@keyframes modalAnimation {
	0% {
		transform: translateY(100vh);
	}
	100% {
		transform: translateY(0);
	}
}

@media screen and (min-width: 800) {
	.container {
		position: relative;
		width: 490px;
		border-radius: 4px;
		box-shadow: 0 2px 6px 0 rgba(36, 47, 51, 0.16);
		border: solid 1px #fbfbfb;
		background-color: #fff;
		min-height: auto;
	}
	.content {
		height: auto;
		background: #ffffff;
	}

	.header {
		position: absolute;
		right: 0;
		justify-content: flex-end;
		border: none;
	}

	.title {
		display: none;
	}
}
