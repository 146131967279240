.container {
	padding: 8px 0;
}

.title {
	display: flex;
	align-items: center;
	padding: 8px 0;
}

.title > * {
	margin-right: 10px;
}

.icon {
	height: 24px;
	width: 24px;
	object-fit: contain;
	border-style: solid;
	border-radius: 4px;
	border: solid 1px #d3d3db;
	padding: 10px;
}

.subtitle {
	text-transform: capitalize;
}