.container {
	display: grid;
	grid-template-columns: 3fr 1fr;
	grid-template-rows: '20px' 1fr;
	grid-template-areas: "title action" "desc action";
	padding: 8px 0;
}

.action {
	grid-area: action;
	justify-self: center;
	align-self: center;
	cursor: pointer;

	font-size: 12px;
	font-weight: 600;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.67;
	letter-spacing: 0.2px;
	text-align: right;
	color: #2077d5;
}

.title {
	grid-area: title;
	/* TEXT */
	font-size: 12px;
	font-weight: 600;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.67;
	letter-spacing: 0.18px;
	color: #6f7676;
}

.desc {
	grid-area: desc;
	font-size: 12px;
	font-weight: 600;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.71;
	letter-spacing: normal;
	color: #242f33;
}