:root {
	--blue: #2077d5;
	--grey90: #9da2a3;
	--black: #242f33;
	--red:#d0021b;
}

.input {
	width: calc(100% - 16px);
	border-radius: 4px;
	border: solid 1px #9da2a3;
	height: 20px;
	padding: 8px;
	outline: none;

}

.input:disabled {
	background: #eaeaea;
    cursor: no-drop;
    border: none;
}

.input:focus {
	border: solid 1px var(--blue);
}

.error {
	background-color: rgba(208, 2, 27, 0.08);
	border: solid 1px var(--red);
}

.complete {
	border: solid 1px var(--black);
}

.inline {
	display: inline;
}
