/* React-dates styles */
.CalendarMonthGrid {
    background-color: transparent;
  
  }

  .CalendarMonth {
    background-color: transparent;
}

.DayPicker__withBorder {
    box-shadow: none;
    border: none;
}

.DayPicker__horizontal {
    background-color: transparent;
}
.DayPicker {
    background-color: transparent;
    position: relative;
    text-align: left;
}
.CalendarDay__selected, .CalendarDay__selected:active, .CalendarDay__selected:hover, .CalendarDay__selected_span, .CalendarDay__hovered_span, .CalendarDay__hovered_span:hover, .CalendarDay__selected_span:hover  {
    background: #a2ccfa;
    border: 1px double #2077d5;
    color: #2077d5;
}
/* .CalendarDay__selected_span:hover {
    background: #33dacd;
    border: 1px double #33dacd;
    color: #fff;
} */
