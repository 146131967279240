.background {
	border-radius: 3px;
	background-color: #ebebf0;
}

.progressBar {
	height: 4px;
	border-radius: 3px;
	/* background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.05)); */
	transition: 0.4s linear;
	transition-property: width;
}

.step1 .progressBar {
	width: 0%;
	background-color: #9da2a3;
	/* animation: progressAnimation 6s; */
}

.step2 .progressBar {
	width: 50%;
	background-color: #9da2a3;
}

.step3 .progressBar {
	width: 100%;
	background-color: #9da2a3;
}

@keyframes progressAnimation {
	0% {
		width: 5%;
	}
	100% {
		width: 85%;
	}
}