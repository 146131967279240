.containerRange {
	display: flex;
  align-items: center;
}

.date {
  min-width: 80px;
  text-align: center;
}

.containerRange:last-of-type {
  margin-bottom: 0;
}