.desktopSteps {
	width: 100%;
}
.container {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
}

.content {
	flex-grow: 1;
}

.form {
}

@media screen and (min-width: 800px) {
	.container {
		display: flex;
		flex-direction: column;
		align-items: center;
		min-height: calc(100vh - 57px);
	}
	.content {
		display: flex;
		justify-content: center;
		flex-grow: 1;
		padding-top: 20px;
		padding-bottom: 20px;
	}
	.form {
		display: flex;
		flex-direction: column;
		width: 420px;
	}
	.summary {
		width: 380px;
		position: sticky;
		top: 100px;
		margin-top: 70px;
	}
	.actions {
		width: 720px;
	}
}
