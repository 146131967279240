.container {
	min-height: 36px;
	/* width: 100%; */
	padding: 10px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color: #fafafa;

	border-bottom: solid 1px #d3d3db;
}
.content {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.progressBar {
	width: 50%;
	padding: 10px;
}

.labels {
	width: 90%;
	display: flex;
	justify-content: space-between;
}

.label {
	text-align: center;
}

@media screen and (min-width: 800px) {
	.progressBar {
		display: none;
	}
	.content {
		/* justify-content: flex-start; */
		display: flex;
		justify-content: flex-start;
		width: 720px;
	}
	.labels {
		width: 100%;
		justify-content: flex-start;
	}
	.label {
		margin-right: 54px !important;
	}
}
