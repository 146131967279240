.container {
	flex-grow: 1;
	padding: 8px 16px;
}

.cardNumberContainer {
	display: flex;
	align-items: center;
}

.cardNumber {
	flex-grow: 1;
}

.title {
	padding-bottom: 10px;
}

.subtitle {
	font-size: 14px !important;
	font-weight: 600 !important;
}

.details {
	padding: 8px 0;
}

.paddingRight {
	padding-right: 4px;
}

.paddingLeft {
	padding-left: 4px;
}

.cardIcon {
	margin-left: 8px;
	margin-top: 3px;
	height: 30px;
}

.cardData {
	display: flex;
	width: 100%;
}

.cardData > div {
	margin-right: 10px;
	flex-grow: 1;
}

.cardData > div:last-child {
	margin-right: 0;
}

.noPaddingLeft {
	padding-left: 0 !important;
}

.noPaddingRight {
	padding-right: 0 !important;
}

.card {
	cursor: pointer;
	transition: all 0.3s linear !important;
	margin-bottom: 12px !important;
	padding: 13px !important;
}

.card:not(.selected):hover {
	border: 1px solid #3978ce !important;
}

.card.selected {
	cursor: default !important;
	border: none !important;
	padding: 0 !important;
}

.separator {
	margin-bottom: 24px !important;
}

.black90 {
	color: #65747a !important;
}

.black10 {
	color: #ebebef !important;
}

.bgBlack10 {
	background-color: #ebebef !important;
}

.mb8 {
	margin-bottom: 8px !important;
}

.instructions {
	line-height: 24px !important;
}

.transferDetails {
	padding: 16px !important;
	margin-bottom: 8px !important;
}

.transferDetailsTable {
	width: 100% !important;
}

.textRight {
	text-align: right !important;
}

.bold {
	font-weight: 600 !important;
}

.borderNone {
	border: none !important;
}

.header {
	margin-bottom: 8px !important;
}
