.container {
	padding: 16px;
}

.daypicker {
	margin: 20px 0;
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 300px;
}

.tripDuration {
	padding: 5px;
	border: solid 1px #d3d3db;
	border-radius: 4px;
}

.durationContainer {
	margin : 16px 0;
}

.selectedDates {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	margin: 10px 0;
}